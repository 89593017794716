import React from 'react';
import moment from "moment-timezone";
import CustomCellRender from "components/common/CustomCellRender";

export const laoImportOrderDetailsColumns = {
  columnOrder: [
    {
      title: 'order_#', field: "orderNum",
      render: rowData => {
        return <CustomCellRender id="orderNum" value={rowData.multiRequstedDeliveryDateInd ? `${rowData.orderNum} *` : rowData.orderNum} />
      }
    },
    {
      title: 'purchase_order_trade_PO', field: "ftzPoNum",
      render: rowData => {
        return <CustomCellRender id="ftzPoNum" value={rowData?.ftzPoNum} />
      }
    },
    {
      title: 'invoice_num', field: "invoiceNum",
      render: rowData => {
        return <CustomCellRender id="invoiceNum" value={rowData.invoiceNum} />
      }
    },
    {
      title: 'invoicing_date', field: "invoiceDate",
      render: rowData => {
        return <CustomCellRender id="invoiceDate" value={rowData.invoiceDate} />
      }
    },
    {
      title: 'vendor_number', field: "vendorNumber",
      render: rowData => {
        return <CustomCellRender id="vendorNumber" value={rowData.vendorNumber} />
      }
    },
    {
      title: 'vendor_name', field: "vendorName",
      render: rowData => {
        return <CustomCellRender id="vendorName" value={rowData.vendorName} />
      }
    },
    {
      title: 'free_trade_destination_plant', field: "ftzOrderDestinationId",
      render: rowData => {
        return <CustomCellRender id="ftzOrderDestinationId" value={rowData?.ftzOrderDestination?.id} />
      }
    },
    {
      title: 'free_trade_destination_description', field: "ftzOrderDestinationName",
      render: rowData => {
        return <CustomCellRender id="ftzOrderDestinationName" value={rowData?.ftzOrderDestination?.name} />
      }
    },
    {
      title: 'destination_plant', field: "orderDestinationId",
      render: rowData => {
        return <CustomCellRender id="orderDestinationId" value={rowData.orderDestination.id} />
      }
    },
    {
      title: 'destination_description', field: "orderDestinationName",
      render: rowData => {
        return <CustomCellRender id="orderDestinationName" value={rowData.orderDestination.name} />
      }
    },
    {
      title: 'origin_port', field: "importOriginPort",
      render: rowData => {
        return <CustomCellRender id="importOriginPort" value={rowData.importOriginPort} />
      }
    },
    {
      title: 'international_shipper', field: "importInternationalShipper",
      render: rowData => {
        return <CustomCellRender id="importInternationalShipper" value={rowData.importInternationalShipper} />
      }
    },
    {
      title: 'booking_number', field: "importBookingNumber",
      render: rowData => {
        return <CustomCellRender id="importBookingNumber" value={rowData.importBookingNumber} />
      }
    },
    {
      title: 'container_id', field: "importContainerId",
      render: rowData => {
        return <CustomCellRender id="importContainerId" value={rowData.importContainerId} />
      }
    },
    {
      title: 'import_shipment_number', field: "importShipmentNumber",
      render: rowData => {
        return <CustomCellRender id="importShipmentNumber" value={rowData.importShipmentNumber} />
      }
    },
    {
      title: 'load_number', field: "firstOriginShipmentNum",
      render: rowData => {
        return <CustomCellRender id="firstOriginShipmentNum" value={rowData.firstOriginShipmentNum} />
      }
    },
    {
      title: 'delivery_number', field: "deliveryNum",
      render: rowData => {
        return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
      }
    },
    // {
    //   title: 'import_pre_shipment_status', field: "importPreShipmentStatus",
    //   render: rowData => {
    //     return <CustomCellRender id="importPreShipmentStatus" value={rowData.importPreShipmentStatus} />
    //   }
    // },
    {
      title: 'import_post_shipment_status', field: "importPostShipmentStatus",
      render: rowData => {
        return <CustomCellRender id="importPostShipmentStatus" value={rowData.importPostShipmentStatus} />
      }
    },
    {
      title: 'import_destination_port', field: "importDestinationPort",
      render: rowData => {
        return <CustomCellRender id="importDestinationPort" value={rowData.importDestinationPort} />
      }
    },
    {
      title: 'import_license_number', field: "importLicenseNumber",
      render: rowData => {
        return <CustomCellRender id="importLicenseNumber" value={rowData.importLicenseNumber} />
      }
    },
    {
      title: 'import_license_date', field: "importLicenseDate",
      render: rowData => {
        return <CustomCellRender id="importLicenseDate" value={rowData.importLicenseDate} />
      }
    },
    {
      title: 'delivery_date', field: "requstedDeliveryDate",
      render: rowData => {
        return <CustomCellRender id="requstedDeliveryDate" value={rowData.requstedDeliveryDate} />
      }
    },
    {
      title: 'shipment_mode', field: "importShipmentMode",
      render: rowData => {
        return <CustomCellRender id="importShipmentMode" value={rowData.importShipmentMode} />
      }
    },
    {
      title: 'import_container_type', field: "importContainerType",
      render: rowData => {
        return <CustomCellRender id="importContainerType" value={rowData.importContainerType} />
      }
    },
    // {
    //   title: 'import_shipment_number', field: "importShipmentNumber",
    //   render: rowData => {
    //     return <CustomCellRender id="importShipmentNumber" value={rowData.importShipmentNumber} />
    //   }
    // },
    {
      title: 'incoterms', field: "incoterms",
      render: rowData => {
        return <CustomCellRender id="incoterms" value={rowData.incoterms} />
      }
    },
    {
      title: 'origin_country', field: "importOrderOriginCountry",
      render: rowData => {
        return <CustomCellRender id="importOrderOriginCountry" value={rowData.importOrderOriginCountry} />
      }
    },
    {
      title: 'destination_country', field: "importOrderDestinationCountry",
      render: rowData => {
        return <CustomCellRender id="importOrderDestinationCountry" value={rowData.importOrderDestinationCountry} />
      }
    },
    {
      title: 'bill_lading', field: "importBillOfLading",
      render: rowData => {
        return <CustomCellRender id="importBillOfLading" value={rowData.importBillOfLading} />
      }
    },
    {
      title: 'bid_lane_number', field: "importBidLaneNumber",
      render: rowData => {
        return <CustomCellRender id="importBidLaneNumber" value={rowData.importBidLaneNumber} />
      }
    },
    {
      title: 'import_etd_date', field: "importEtd",
      render: rowData => {
        return <CustomCellRender id="importEtd" value={rowData.importEtd} />
      }
    },
    {
      title: 'import_eta_date', field: "importEta",
      render: rowData => {
        return <CustomCellRender id="importEta" value={rowData.importEta} />
      }
    },
    {
      title: 'import_declaration', field: "importDeclaration",
      render: rowData => {
        return <CustomCellRender id="importDeclaration" value={rowData.importDeclaration} />
      }
    },
    {
      title: 'import_id_date', field: "importIDDate",
      render: rowData => {
        return <CustomCellRender id="importIDDate" value={rowData.importIDDate} />
      }
    },
    {
      title: 'import_customs_channel', field: "importCustomsChannel",
      render: rowData => {
        return <CustomCellRender id="importCustomsChannel" value={rowData.importCustomsChannel} />
      }
    },
    {
      title: 'import_free_period_end_date', field: "importFreePeriodEndDate",
      render: rowData => {
        return <CustomCellRender id="importFreePeriodEndDate" value={rowData.importFreePeriodEndDate} />
      }
    },
    {
      title: 'import_kc_dock_time', field: "importKCDockTime",
      render: rowData => {
        return <CustomCellRender id="importKCDockTime" value={rowData.importKCDockTime} />
      }
    },
    {
      title: 'import_req_date_cont_at_plant', field: "importReqDateContAtPlant",
      render: rowData => {
        return <CustomCellRender id="importReqDateContAtPlant" value={rowData.importReqDateContAtPlant} />
      }
    },
    {
      title: 'import_time_of_arrival_in_plant', field: "importTimeOfArrivalInPlant",
      render: rowData => {
        return <CustomCellRender id="importTimeOfArrivalInPlant" value={rowData.importTimeOfArrivalInPlant} />
      }
    },
    {
      title: 'import_date_cont_at_plant', field: "importDateContAtPlant",
      render: rowData => {
        return <CustomCellRender id="importDateContAtPlant" value={rowData.importDateContAtPlant} />
      }
    },
    {
      title: 'import_hour_cont_at_plant', field: "importHourContAtPlant",
      render: rowData => {
        return <CustomCellRender id="importHourContAtPlant" value={rowData.importHourContAtPlant} />
      }
    },
    {
      title: 'import_transhipment_etd', field: "importTranshipmentETD",
      render: rowData => {
        return <CustomCellRender id="importTranshipmentETD" value={rowData.importTranshipmentETD} />
      }
    },
    {
      title: 'import_customs_leave', field: "importCustomsLeave",
      render: rowData => {
        return <CustomCellRender id="importCustomsLeave" value={rowData.importCustomsLeave} />
      }
    },
    {
      title: 'import_container_return_date', field: "importContainerReturnDate",
      render: rowData => {
        return <CustomCellRender id="importContainerReturnDate" value={rowData.importContainerReturnDate} />
      }
    }
    
  ],
  columnConfiguration: (d) => {
    return {
      invoiceDate: d?.invoiceDate ? moment(d.invoiceDate).format(`DD-MM-YYYY`) : '-',
      importLicenseDate: d?.importLicenseDate ? moment(d.importLicenseDate).format(`DD-MM-YYYY`) : '-',
      requstedDeliveryDate: d?.requstedDeliveryDate ? moment(d.requstedDeliveryDate).format(`DD-MM-YYYY`) : '-',
      orderDestinationId: d?.finalOrderDestination?.id ? d.finalOrderDestination.id : '-',
      orderDestinationName: d?.finalOrderDestination?.name ? d.finalOrderDestination.name : '-',
      ftzOrderDestinationId: d?.ftzOrderDestination?.id ? d?.ftzOrderDestination?.id : '-',
      ftzOrderDestinationName: d?.ftzOrderDestination?.name ? d?.ftzOrderDestination?.name : '-',
      importEta: d?.importEta ? moment(d.importEta).format(`DD-MM-YYYY`) : '-',
      importEtd: d?.importEtd ? moment(d.importEtd).format(`DD-MM-YYYY`) : '-',
      importIDDate: d?.importIDDate ? moment(d.importIDDate).format(`DD-MM-YYYY`) : '-',
      importFreePeriodEndDate: d?.importFreePeriodEndDate ? moment(d.importFreePeriodEndDate).format(`DD-MM-YYYY`) : '-',
      importReqDateContAtPlant: d?.importReqDateContAtPlant ? moment(d.importReqDateContAtPlant).format(`DD-MM-YYYY`) : '-',
      importDateContAtPlant: d?.importDateContAtPlant ? moment(d.importDateContAtPlant).format(`DD-MM-YYYY`) : '-',
      importTranshipmentETD: d?.importTranshipmentETD ? moment(d.importTranshipmentETD).format(`DD-MM-YYYY`) : '-',
      importCustomsLeave: d?.importCustomsLeave ? moment(d.importCustomsLeave).format(`DD-MM-YYYY`) : '-',
      importContainerReturnDate: d?.importContainerReturnDate ? moment(d.importContainerReturnDate).format(`DD-MM-YYYY`) : '-'
    }
  }
};

export const laoImportExpandDetailsColumns = {
  columnOrder: [
    { title: 'po_item', field: 'itemPosNum', emptyValue: "-" },
    { title: 'sku_material', field: 'materialNum', emptyValue: "-" },
    { title: 'material_description', field: 'itemDesc', emptyValue: "-" },
    { title: 'delivery_date', field: 'itemRequstedDeliveryDate', emptyValue: "-" },
    { title: 'material_type', field: 'materialType', emptyValue: "-" },    
    { title: 'po_quantity', field: 'orderQty', emptyValue: "-" },
    { title: 'quantity_packed', field: 'packedQty', emptyValue: "-" },
    { title: 'volume_packed', field: 'packedQtyVolume', emptyValue: "-" },
    // {
    //   title: 'volume_packed', field: 'packedQtyVolume', emptyValue: "-",
    //   render: rowData => {
    //     return <CustomCellRender id="packedQtyVolume" value={rowData.packedQtyVolume * rowData.packedQty} />
    //   }
    // },
    { title: 'order_unit', field: 'qtyUom', emptyValue: "-" },
    { title: 'import_pre_shipment_status', field: 'importPreShipmentStatus', emptyValue: "-"},
    { title: 'import_tentative_etd', field: 'importTentativeETD', emptyValue: "-"},
    { title: 'import_tentative_eta', field: 'importTentativeETA', emptyValue: "-"},
    { title: 'delivery_completion_ind', field: 'deliveryCompletionInd', emptyValue: "-"}
  ],
  columnConfiguration: (d) => {
    return {
      // Keeping this intact in case of future conversions of field

      // itemPosNum: get(d, "itemPosNum", "-"),
      // materialNum: get(d, "materialNum", "-"),
      // itemDesc: get(d, "itemDesc", "-"),
      // orderQty: get(d, "orderQty", "-"),
      // packedQty: get(d, "packedQty", "-"),
      // packedQtyVolume: d?.packedQtyVolume * d?.packedQty
    }
  }
}